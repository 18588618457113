WiseMetering.Views.SeuObjectives = Backbone.Marionette.Layout.extend({
    template: 'templates/zones/objectives',
    className: 'dashboard full-height',

    regions: {
        table: '#table'
    },

    bindObjectiveChanges: function() {
        this.listenTo(WiseMetering.objectives, 'add', this.redrawWidget.bind(this));
        this.listenTo(WiseMetering.objectives, 'remove', this.redrawWidget.bind(this));
        this.listenTo(WiseMetering.objectives, 'change', this.redrawWidget.bind(this));
    },

    unbindObjectiveChanges() {
        this.stopListening(WiseMetering.objectives, 'add');
        this.stopListening(WiseMetering.objectives, 'remove');
        this.stopListening(WiseMetering.objectives, 'change');
    },

    onRender: function() {
        this.table.show(new WiseMetering.Views.ObjectivesAccordion({ model: this.model.indicator() }));
        this.prepareWidget();
    },

    onShow: function() {
        this.renderWidget();
        this.bindObjectiveChanges();
    },

    onClose: function() {
        Object.values(this.widgets).forEach(w => w.close());
        this.unbindObjectiveChanges();
    },

    prepareWidget: function() {
        this.widgets = {};

        const buildWidget = (data, title, unit) => {
            const el = `#widgets`;
            return new WiseMetering.Views.Ui.Widget.Objective({ el, data, title, unit, period: 'ytd' });
        };

        const objectives = WiseMetering.objectives.where({
            indicator_id: this.model.indicator().id,
            year: moment().year()
        })

        objectives.forEach(function(objective) {
            const
                data = WiseMetering.Analytics.getObjectiveAnalysis(objective),
                indicator = objective.indicator(),
                name = indicator.get('name'),
                type = objective.get('analysis_type'),
                unit = type === 'cost' ? null : indicator.get('unit');

            if (_.isEmpty(data)) return;

            this.widgets[`${name}_objective_${type}`] = buildWidget(data, `${name} - ${i18next.t(`common.${type}`)}`, unit);
        }.bind(this));
    },

    renderWidget: function() {
        Object.values(this.widgets).forEach(w => w.render(false));
        this.$('#widgets').append('<div class="ghost-item">');
        Lmit.Utils.triggerResize();
        Object.values(this.widgets).forEach(w => w.drawChart());
    },

    redrawWidget: function() {
        this.$el.find('#widgets').empty();
        this.prepareWidget();
        this.renderWidget();
    }

});

const getCurrentObjectives = building_id => {
    const year = moment().year();
    return WiseMetering.objectives.where({ year, building_id });
};
