WiseMetering.Views.IndexZoneAttributes = WiseMetering.Views.TableIndex.extend({
    icon: 'attributes',
    name: 'Zone Attributes',

    createTable: function() {
        return new WiseMetering.Views.Table({
            id: 'zone_attributes',
            collection: WiseMetering.zone_attributes,
            modelSerializer: model => {
                const values = WiseMetering.zone_attribute_values.where({ zone_attribute_id: model.id });
                const json = model.toJSON();
                json.unit = model.unit().get('name');
                if (model.symbol()) {
                    json.unit += ` (${model.symbol()})`;
                }
                json.used = values.length > 0;
                json.values = values.length;
                return json;
            },
            columns: {
                used: {
                    mRender: used => JST['templates/ui/components/circle']({
                        color: WiseMetering.SemaphorColors[used ? 'green' : 'orange'],
                        title: used ? 'Used' : 'Unused'
                    }),
                    width: '8px'
                },
                name: 'Name',
                unit_id: {
                    title: i18next.t('glossary.unit'),
                    mRender: unit_id => WiseMetering.units.get(unit_id).get('symbol'),
                },
                metadata_type: 'Type',
                unit: 'Unit',
                values: 'Usage count'
            },
            toolbar_actions: {
                new: {
                    view_call: () => new WiseMetering.Views.NewZoneAttribute({
                        collection: WiseMetering.zone_attributes,
                        model: new WiseMetering.Model.ZoneAttribute
                    })
                },
                edit: {
                    permission: 'administrator',
                    view_call: model => new WiseMetering.Views.EditZoneAttribute({ model })
                },
                show: {
                    view_call: model => new WiseMetering.Views.EditZoneAttribute({ model })
                },
                delete: {
                    permission: 'administrator',
                    view_call: model => new WiseMetering.Views.Modal.Destroy({
                        model,
                        title: () => i18next.t('modal.title.zone_attributes.destroy')
                    })

                }
            }
        });
    }
});
